import { Dialog, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export default function NotFound() {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Dialog
      open={true}
      sx={{ zIndex: 20000, p: 2 }}
      onClose={() => {
        searchParams.delete("box");
        setSearchParams(searchParams);
      }}
    >
      <Typography>Not found</Typography>
    </Dialog>
  );
}
